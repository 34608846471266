// Event
export const EVENT_POLL = 'POLL';
export const EVENT_NO_RESPONSE = 'NO_RESPONSE';
export const EVENT_ONLINE = 'ONLINE';
export const EVENT_OFFLINE = 'OFFLINE';
export const EVENT_IDLE = 'IDLE';
export const EVENT_PUBLISH = 'PUBLISH';
export const EVENT_REPUBLISH = 'REPUBLISH';
export const EVENT_PUBLISHED = 'PUBLISHED';
export const EVENT_TAKE = 'TAKE';
export const EVENT_TAKEN = 'TAKEN';
export const EVENT_NAVIGATE = 'NAVIGATE';
export const EVENT_DIRECTIONS = 'DIRECTIONS';
export const EVENT_ARRIVE = 'ARRIVE';
export const EVENT_ARRIVED = 'ARRIVED';
export const EVENT_GROUND = 'GROUND';
export const EVENT_GROUNDED = 'GROUNDED';
export const EVENT_FINISH = 'FINISH';
export const EVENT_FINISHED = 'FINISHED';
export const EVENT_CONFIRM = 'CONFIRM';
export const EVENT_MC_CANCEL = 'MC_CANCEL';
export const EVENT_MC_CANCELLED = 'CANCELLED'; // TODO: change to 'MC_CANCELLED' as soon as server is refactored and all mobile apps are compatible
export const EVENT_GUARD_CANCEL = 'GUARD_CANCEL';
export const EVENT_GUARD_CANCELLED = 'GUARD_CANCELLED';
export const EVENT_CLOSE = 'CLOSE';
export const EVENT_CLOSED = 'CLOSED';
export const EVENT_PAUSE = 'PAUSE';
export const EVENT_PAUSED = 'PAUSED';
export const EVENT_REJECT = 'REJECT';
export const EVENT_REJECTED = 'REJECTED';
export const EVENT_RESUME = 'RESUME';
export const EVENT_RESUMED = 'RESUMED';
export const EVENT_POSITION = 'POSITION';
export const EVENT_NOTE = 'NOTE';
export const EVENT_NOTED = 'NOTED';
export const EVENT_REVIEW = 'REVIEW';
export const EVENT_REVIEWED = 'REVIEWED';
export const EVENT_MC_REVIEW = 'MC_REVIEW';
export const EVENT_MC_REVIEWED = 'MC_REVIEWED';
export const EVENT_MC_ESCALATE = 'MC_ESCALATE';
export const EVENT_MC_ESCALATED = 'MC_ESCALATED';
export const EVENT_MC_APPROVE = 'MC_APPROVE';
export const EVENT_MC_APPROVED = 'MC_APPROVED';
export const EVENT_MC_DISPUTE = 'MC_DISPUTE';
export const EVENT_MC_DISPUTED = 'MC_DISPUTED';
export const EVENT_ROOT_RESOLVE = 'ROOT_RESOLVE';
export const EVENT_ROOT_RESOLVED = 'ROOT_RESOLVED';
export const EVENT_RESET = 'RESET';
export const EVENT_TIMEOUT = 'TIMEOUT';
export const EVENT_ERROR = 'ERROR';
export const EVENT_PING = 'PING';
export const EVENT_PONG = 'PONG';
export const EVENT_REFRESH = 'REFRESH';
export const EVENT_REFRESHED = 'REFRESHED';
export const EVENT_DASHBOARD_ALL = 'DASHBOARD_ALL';
export const EVENT_DASHBOARD_DELTA = 'DASHBOARD_DELTA';
export const EVENT_DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';
export const EVENT_DASHBOARD_UPDATE = 'DASHBOARD_UPDATE';
export const EVENT_UNKNOWN = 'UNKNOWN';
export const EVENT_ACK = 'ACK';
export const EVENT_DEVICE = 'DEVICE';
export const EVENT_BATCH = 'BATCH';
export const EVENT_BATCHED = 'BATCHED';
export const EVENT_LOCKBOX = 'LOCKBOX';
export const EVENT_CALL = 'CALL';
export const EVENT_ECHO_SYNC = 'ECHO_SYNC';
export const EVENT_ECHO_ASYNC = 'ECHO_ASYNC';
export const EVENT_BILLING_STATUS_CHANGE = 'BILLING_STATUS_CHANGE';
export const EVENT_BILLING_STATUS_CHANGED = 'BILLING_STATUS_CHANGED';
export const EVENT_LIST = [
  EVENT_POLL,
  EVENT_NO_RESPONSE,
  EVENT_ONLINE,
  EVENT_OFFLINE,
  EVENT_IDLE,
  EVENT_PUBLISH,
  EVENT_REPUBLISH,
  EVENT_PUBLISHED,
  EVENT_TAKE,
  EVENT_TAKEN,
  EVENT_NAVIGATE,
  EVENT_DIRECTIONS,
  EVENT_ARRIVE,
  EVENT_ARRIVED,
  EVENT_GROUND,
  EVENT_GROUNDED,
  EVENT_FINISH,
  EVENT_FINISHED,
  EVENT_CONFIRM,
  EVENT_MC_CANCEL,
  EVENT_MC_CANCELLED,
  EVENT_GUARD_CANCEL,
  EVENT_GUARD_CANCELLED,
  EVENT_CLOSE,
  EVENT_CLOSED,
  EVENT_PAUSE,
  EVENT_PAUSED,
  EVENT_REJECT,
  EVENT_REJECTED,
  EVENT_RESUME,
  EVENT_RESUMED,
  EVENT_POSITION,
  EVENT_NOTE,
  EVENT_NOTED,
  EVENT_REVIEW,
  EVENT_REVIEWED,
  EVENT_MC_REVIEW,
  EVENT_MC_REVIEWED,
  EVENT_MC_ESCALATE,
  EVENT_MC_ESCALATED,
  EVENT_MC_APPROVE,
  EVENT_MC_APPROVED,
  EVENT_MC_DISPUTE,
  EVENT_MC_DISPUTED,
  EVENT_ROOT_RESOLVE,
  EVENT_ROOT_RESOLVED,
  EVENT_RESET,
  EVENT_TIMEOUT,
  EVENT_ERROR,
  EVENT_PING,
  EVENT_PONG,
  EVENT_UNKNOWN,
  EVENT_REFRESH,
  EVENT_REFRESHED,
  EVENT_DASHBOARD_ALL,
  EVENT_DASHBOARD_DELTA,
  EVENT_DASHBOARD_SUCCESS,
  EVENT_DASHBOARD_UPDATE,
  EVENT_ACK,
  EVENT_DEVICE,
  EVENT_BATCH,
  EVENT_BATCHED,
  EVENT_ECHO_SYNC,
  EVENT_ECHO_ASYNC,
  EVENT_LOCKBOX,
  EVENT_CALL,
  EVENT_BILLING_STATUS_CHANGE,
  EVENT_BILLING_STATUS_CHANGED,
];

export const PORTAL_LAST_LOCATION = 'PORTAL_LAST_LOCATION';
export const RSPNDR_LOGIN_COMPANY_ID = 'RSPNDR_LOGIN_COMPANY_ID';
export const RSPNDR_LOGIN_EMPLOYEE_ID = 'RSPNDR_LOGIN_EMPLOYEE_ID';

export type RSPNDR_COUNTRY_TYPE = {
  short: string;
  name: string;
  alt?: string[];
};
export const RSPNDR_COUNTRIES: RSPNDR_COUNTRY_TYPE[] = [
  { short: 'CA', name: 'Canada' },
  { short: 'US', name: 'United States' },
  { short: 'GB', name: 'United Kingdom' },
  { short: 'RS', name: 'Serbia' },
  { short: 'NL', name: 'Netherlands' },
  { short: 'CH', name: 'Switzerland'},
];

export type RSPNDR_PROVINCE_TYPE = {
  short: string;
  name: string;
  country: string;
  alt?: string[];
};
export const RSPNDR_PROVINCES: RSPNDR_PROVINCE_TYPE[] = [
  { short: 'AB', name: 'Alberta', country: 'CA' },
  { short: 'BC', name: 'British Columbia', country: 'CA' },
  { short: 'MB', name: 'Manitoba', country: 'CA' },
  { short: 'NB', name: 'New Brunswick', country: 'CA' },
  {
    short: 'NL',
    name: 'Newfoundland and Labrador',
    country: 'CA',
    alt: ['Newfoundland', 'Labrador'],
  },
  { short: 'NS', name: 'Nova Scotia', country: 'CA' },
  { short: 'NU', name: 'Nunavut', country: 'CA' },
  { short: 'NT', name: 'Northwest Territories', country: 'CA' },
  { short: 'ON', name: 'Ontario', country: 'CA' },
  { short: 'PE', name: 'Prince Edward Island', country: 'CA' },
  { short: 'QC', name: 'Quebec', country: 'CA' },
  { short: 'SK', name: 'Saskatchewan', country: 'CA' },
  { short: 'YT', name: 'Yukon', country: 'CA' },
  { short: 'AL', name: 'Alabama', country: 'US' },
  { short: 'AK', name: 'Alaska', country: 'US' },
  { short: 'AZ', name: 'Arizona', country: 'US' },
  { short: 'AR', name: 'Arkansas', country: 'US' },
  { short: 'CA', name: 'California', country: 'US' },
  { short: 'CO', name: 'Colorado', country: 'US' },
  { short: 'CT', name: 'Connecticut', country: 'US' },
  {
    short: 'DC',
    name: 'District of Columbia',
    alt: ['Washington DC', 'Washington D.C.'],
    country: 'US',
  },
  { short: 'DE', name: 'Delaware', country: 'US' },
  { short: 'FL', name: 'Florida', country: 'US' },
  { short: 'GA', name: 'Georgia', country: 'US' },
  { short: 'HI', name: 'Hawaii', country: 'US' },
  { short: 'ID', name: 'Idaho', country: 'US' },
  { short: 'IL', name: 'Illinois', country: 'US' },
  { short: 'IN', name: 'Indiana', country: 'US' },
  { short: 'IA', name: 'Iowa', country: 'US' },
  { short: 'KS', name: 'Kansas', country: 'US' },
  { short: 'KY', name: 'Kentucky', country: 'US' },
  { short: 'LA', name: 'Louisiana', country: 'US' },
  { short: 'ME', name: 'Maine', country: 'US' },
  { short: 'MD', name: 'Maryland', country: 'US' },
  { short: 'MA', name: 'Massachusetts', country: 'US' },
  { short: 'MI', name: 'Michigan', country: 'US' },
  { short: 'MN', name: 'Minnesota', country: 'US' },
  { short: 'MS', name: 'Mississippi', country: 'US' },
  { short: 'MO', name: 'Missouri', country: 'US' },
  { short: 'MT', name: 'Montana', country: 'US' },
  { short: 'NE', name: 'Nebraska', country: 'US' },
  { short: 'NV', name: 'Nevada', country: 'US' },
  { short: 'NH', name: 'New Hampshire', country: 'US' },
  { short: 'NJ', name: 'New Jersey', country: 'US' },
  { short: 'NM', name: 'New Mexico', country: 'US' },
  { short: 'NY', name: 'New York', country: 'US' },
  { short: 'NC', name: 'North Carolina', country: 'US' },
  { short: 'ND', name: 'North Dakota', country: 'US' },
  { short: 'OH', name: 'Ohio', country: 'US' },
  { short: 'OK', name: 'Oklahoma', country: 'US' },
  { short: 'OR', name: 'Oregon', country: 'US' },
  { short: 'PA', name: 'Pennsylvania', country: 'US' },
  { short: 'RI', name: 'Rhode Island', country: 'US' },
  { short: 'SC', name: 'South Carolina', country: 'US' },
  { short: 'SD', name: 'South Dakota', country: 'US' },
  { short: 'TN', name: 'Tennessee', country: 'US' },
  { short: 'TX', name: 'Texas', country: 'US' },
  { short: 'UT', name: 'Utah', country: 'US' },
  { short: 'VT', name: 'Vermont', country: 'US' },
  { short: 'VA', name: 'Virginia', country: 'US' },
  { short: 'WA', name: 'Washington', country: 'US' },
  { short: 'WV', name: 'West Virginia', country: 'US' },
  { short: 'WI', name: 'Wisconsin', country: 'US' },
  { short: 'WY', name: 'Wyoming', country: 'US' },
  { short: 'AS', name: 'American Samoa', country: 'US' },
  { short: 'GU', name: 'Guam', country: 'US' },
  { short: 'MP', name: 'Northern Mariana Islands', country: 'US' },
  { short: 'PR', name: 'Puerto Rico', country: 'US' },
  { short: 'UM', name: 'United States Minor Outlying Islands', country: 'US' },
  { short: 'VI', name: 'Virgin Islands', country: 'US' },
  { short: 'Valjevo', name: 'Valjevo', country: 'RS' },
  { short: 'AG', name: 'Aargau', country: 'CH' },
  { short: 'AR', name: 'Appenzell Ausserrhoden', country: 'CH' },
  { short: 'AI', name: 'Appenzell Innerrhoden', country: 'CH' },
  { short: 'BL', name: 'Basel-Landschaft', country: 'CH' },
  { short: 'BS', name: 'Basel-Stadt', country: 'CH' },
  { short: 'BE', name: 'Bern', country: 'CH' },
  { short: 'FR', name: 'Fribourg', country: 'CH' },
  { short: 'GE', name: 'Geneva', country: 'CH' },
  { short: 'GL', name: 'Glarus', country: 'CH' },
  { short: 'GR', name: 'Graubünden', country: 'CH' },
  { short: 'JU', name: 'Jura', country: 'CH' },
  { short: 'LU', name: 'Lucerne', country: 'CH' },
  { short: 'NE', name: 'Neuchâtel', country: 'CH' },
  { short: 'NW', name: 'Nidwalden', country: 'CH' },
  { short: 'OW', name: 'Obwalden', country: 'CH' },
  { short: 'SH', name: 'Schaffhausen', country: 'CH' },
  { short: 'SZ', name: 'Schwyz', country: 'CH' },
  { short: 'SO', name: 'Solothurn', country: 'CH' },
  { short: 'SG', name: 'St. Gallen', country: 'CH' },
  { short: 'TG', name: 'Thurgau', country: 'CH' },
  { short: 'TI', name: 'Ticino', country: 'CH' },
  { short: 'UR', name: 'Uri', country: 'CH' },
  { short: 'VS', name: 'Valais', country: 'CH' },
  { short: 'VD', name: 'Vaud', country: 'CH' },
  { short: 'ZG', name: 'Zug', country: 'CH' },
  { short: 'ZH', name: 'Zürich', country: 'CH' },
];

export type RSPNDR_SELECT_ITEM = { value: string; description: string };
export type RSPNDR_CITY_LIST_ITEM = {
  country: string;
  province: string;
  city: string;
};
export type RSPNDR_INVOICE_LIST_ITEM = {
  _id: string;
  value: string;
  invoiceNumber: string;
  start: number;
  end: number;
  dateRangeDesc: string;
};

export type RspndrPresetDateRangeType =
  | 'NO_PRESET'
  | 'TODAY'
  | 'THIS_WEEK'
  | 'LAST_MONTH'
  | 'THIS_YEAR';

export const RSPNDR_PRESET_DATE_RANGE_TYPE_DESC = {
  NO_PRESET: 'Date Range',
  TODAY: 'Today',
  THIS_WEEK: 'This week',
  LAST_MONTH: 'Last month',
  THIS_YEAR: 'This year',
};

export const POSITION_INACTIVE_THRESHOLD_MS = 30 * 60 * 1000; // 30 minutes
export const POSITION_AWAY_THRESHOLD_MS = 2 * 60 * 60 * 1000; // 2 hours
export const POSITION_DISENGAGED_THRESHOLD_MS = 24 * 60 * 60 * 1000; // 24 hours
export const STATS_POLL_INTERVAL_MS = 10 * 60 * 1000;
export const ACTIVE_ALARMS_POLL_INTERVAL_MS = 5 * 1000;
export const GUARDS_POLL_INTERVAL_MS = 40 * 1000;
export const POSITIONS_POLL_INTERVAL_MS = 15 * 1000;
export const BROWSER_INACTIVE_TIME_LIMIT_SECONDS = 60 * 60;
export const ALARM_PUBLISHED_BUT_UNASSIGNED_THRESHOLD = 30 * 60 * 1000; // 30 minutes
