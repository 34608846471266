import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatTooltipModule, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { MomentModule } from 'ngx-moment';
import { PaginatorI18n } from 'src/assets/i18n/paginator-I18n';
import {
  AuthConfig,
  DefaultOAuthInterceptor,
  OAuthModule,
  OAuthService,
  OAuthStorage,
  OAuthResourceServerErrorHandler,
} from 'angular-oauth2-oidc';
import { MatButtonModule } from '@angular/material/button';
import { environment } from '../environments/environment';
import { RspndrActuatorApi } from './@core/@api/actuator';
import { RspndrAlarmApi } from './@core/@api/alarm';
import { RspndrChargeApi } from './@core/@api/charge';
import { RspndrEventApi } from './@core/@api/event';
import { RspndrIntegrationApi } from './@core/@api/integration';
import { RspndrInvoiceApi } from './@core/@api/invoice';
import { RspndrOrgApi } from './@core/@api/org';
import { RspndrPortalApi } from './@core/@api/portal';
import { RspndrStatApi } from './@core/@api/stat';
import { RspndrUserApi } from './@core/@api/user';
import { RspndrUtilApi } from './@core/@api/util';
import { DirectivesModule } from './@core/@directives/directives.module';
import { RspndrConfig } from './@core/@models/common';
import { RspndrAuthService } from './@core/@services/auth.service';
import { FirebaseService } from './@core/@services/firebase.service';
import { LocaleService } from './@core/@services/locale.service';
import { RspndrStatsService } from './@core/@services/stats.service';
import { clearState } from './@core/meta.reducers';
import { reducers } from './@core/reducers';
import { RspndrSvgIconsModule } from './@core/svg-icons.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppEffects } from './app.effects';
import { HomeEffects } from './home/home.effects';
import { ReportEffects } from './report/report.effects';
import { RspndrStatsComponentModule } from './stats/stats.component.module';
import { TransactionEffects } from './transaction/transaction.effects';
import { UserManagementEffects } from './usermanagement/usermanagement.effects';
import packageJson from '../../package.json';
import { OauthErrorHandler } from './@core/oauth-error-handler';
import { RspndrPatrolsApi } from './@core/@api/patrols';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { extModules } from './build-specifics';
import { ErrorSnackbarComponent } from './error-snackbar/error-snackbar.component';
import { RspndrPortalPositionApi } from './@core/@api/portal-position';

export function createTranslateLoader(http: HttpClient) {
  // version numbering prevents caching issues
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${packageJson.version}`);
}

export function oAuthStorageFactory(): OAuthStorage {
  return localStorage;
}

const authConfig: AuthConfig = {
  issuer: `${environment.keycloakBaseUrl}/realms/${environment.keycloakRealm}`,
  redirectUri: `${window.location.origin}`,
  clientId: environment.keycloakClientId,
  dummyClientSecret: 'secret',
  responseType: 'code',
  scope: 'openid profile email offline_access',
  requireHttps: environment.name !== 'local',
};

Sentry.init({
  dsn: 'https://e66ab6c6345f49a196876c2a52487c6d@o288019.ingest.sentry.io/5751127',
  environment: environment.name,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', 'https://portal.rspndr.io'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

@NgModule({
  declarations: [AppComponent, UnauthorizedComponent, ErrorSnackbarComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    MatDialogModule,
    RspndrStatsComponentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    MomentModule,
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
      metaReducers: [clearState],
    }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([
      AppEffects,
      HomeEffects,
      ReportEffects,
      TransactionEffects,
      UserManagementEffects,
    ]),
    DirectivesModule,
    MatSnackBarModule,
    MatIconModule,
    RspndrSvgIconsModule,
    MatTooltipModule,
    MatSelectModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          `${environment.baseUrl}/api`,
          `${environment.baseUrl}/internal`,
          `${environment.baseUrl}/v2`,
          `${environment.invoiceUrl}/api`,
        ],
        sendAccessToken: true,
      },
    }),
    MatButtonModule,
    extModules,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultOAuthInterceptor,
      multi: true,
    },
    RspndrConfig,
    {
      provide: RspndrAuthService,
      useClass: RspndrAuthService,
      deps: [RspndrConfig, HttpClient, OAuthService, Router],
    },
    RspndrActuatorApi,
    RspndrAlarmApi,
    RspndrChargeApi,
    RspndrEventApi,
    RspndrIntegrationApi,
    RspndrInvoiceApi,
    RspndrOrgApi,
    RspndrPatrolsApi,
    RspndrPortalApi,
    RspndrPortalPositionApi,
    RspndrStatApi,
    RspndrUserApi,
    RspndrUtilApi,
    LocaleService,
    FirebaseService,
    RspndrStatsService,
    OauthErrorHandler,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 300,
      },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 6000,
      },
    },
    {
      provide: OverlayContainer,
      useClass: FullscreenOverlayContainer,
    },
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) =>
        new PaginatorI18n(translateService).getPaginatorIntl(),
    },
    {
      provide: AuthConfig,
      useValue: authConfig,
    },
    {
      provide: OAuthStorage,
      useFactory: oAuthStorageFactory,
    },
    {
      provide: OAuthResourceServerErrorHandler,
      useClass: OauthErrorHandler,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
