import { createReducer, on } from '@ngrx/store';
import { SortDirection } from '@angular/material/sort';
import * as actions from './home.actions';
import { DateRangeModel } from './home.actions';
import { RspndrAlarm, RspndrGuard, RspndrSimpleSubscriberDto } from '../@core/@models/rspndr';
import { Page } from '../@core/@api/portal';

export interface HomeState {
  error: Error;
  currentTab: string;
  activeAlarms: RspndrAlarm[];
  guards: RspndrGuard[];
  completedAlarms: Page<RspndrAlarm>;
  subscriberInfo: RspndrSimpleSubscriberDto[];
  selectedGuardId: string;
  selectedAlarmId: string;
  selectedReportId: string;
  activeAlarmsLastModifiedAt: number;
  activeAlarmsTableLoading: boolean;
  activeAlarmsTableError: boolean;
  activeAlarmFilterStatus: string;
  activeAlarmFilterText: string;
  guardsLastModifiedAt: number;
  guardsTableLoading: boolean;
  guardsTableError: boolean;
  guardFilterStatus: string;
  guardFilterText: string;
  guardDetail: RspndrGuard;
  guardDetailLoading: boolean;
  dispatchAlarmLoading: boolean;
  completedAlarmsLoading: boolean;
  reportFilterDateRange: DateRangeModel;
  reportFilterText: string;
  reportPageSize: number;
  reportPageNumber: number;
  reportSortProperty: string;
  reportSortDirection: SortDirection;
}

export const initialState: HomeState = {
  error: undefined,
  currentTab: undefined,
  activeAlarms: [],
  guards: [],
  completedAlarms: <Page<RspndrAlarm>>{
    content: [],
  },
  subscriberInfo: [],
  selectedGuardId: undefined,
  selectedAlarmId: undefined,
  selectedReportId: undefined,
  activeAlarmsLastModifiedAt: undefined,
  activeAlarmsTableLoading: false,
  activeAlarmsTableError: false,
  activeAlarmFilterStatus: 'ALL',
  activeAlarmFilterText: '',
  guardsLastModifiedAt: undefined,
  guardsTableLoading: false,
  guardsTableError: false,
  guardFilterStatus: 'ALL',
  guardFilterText: '',
  guardDetail: undefined,
  guardDetailLoading: false,
  dispatchAlarmLoading: false,
  completedAlarmsLoading: false,
  reportFilterDateRange: undefined,
  reportFilterText: '',
  reportPageSize: 25,
  reportPageNumber: 0,
  reportSortProperty: 'createdAt',
  reportSortDirection: 'desc',
};

export const getCompletedAlarms = (state: HomeState) => state.completedAlarms;

export const getSubscriberInfo = (state: HomeState) => state.subscriberInfo;
export const getError = (state: HomeState) => state.error;
export const getExceptionCount = (state: HomeState) =>
  state.activeAlarms?.filter(
    (alarm) =>
      (alarm?.state === 'PUBLISHED' || alarm?.state === 'HOLD') &&
      alarm?.exceptions?.some(
        (exception) =>
          exception.code === 'DISPATCH_TIMEOUT' || exception.code === 'DISPATCH_TIMEOUT_WARNING',
      ),
  )?.length;
export const getActiveAlarmsLastModifiedAt = (state: HomeState) => state.activeAlarmsLastModifiedAt;
export const getActiveAlarmsTableLoading = (state: HomeState) => state.activeAlarmsTableLoading;
export const getActiveAlarmsTableError = (state: HomeState) => state.activeAlarmsTableError;
export const getActiveAlarms = (state: HomeState) => state.activeAlarms;
export const getActiveAlarmFilterStatus = (state: HomeState) => state.activeAlarmFilterStatus;
export const getActiveAlarmFilterText = (state: HomeState) => state.activeAlarmFilterText;
export const getDispatchAlarmLoading = (state: HomeState) => state.dispatchAlarmLoading;
export const getCompletedAlarmsLoading = (state: HomeState) => state.completedAlarmsLoading;
export const getReportFilterDateRange = (state: HomeState) => state.reportFilterDateRange;
export const getReportFilterText = (state: HomeState) => state.reportFilterText;
export const getReportPageSize = (state: HomeState) => state.reportPageSize;
export const getReportSortProperty = (state: HomeState) => state.reportSortProperty;
export const getReportSortDirection = (state: HomeState) => state.reportSortDirection;
export const getGuardsLastModifiedAt = (state: HomeState) => state.guardsLastModifiedAt;
export const getGuardsTableLoading = (state: HomeState) => state.guardsTableLoading;
export const getGuardsTableError = (state: HomeState) => state.guardsTableError;
export const getGuards = (state: HomeState) => state.guards;
export const getGuardFilterStatus = (state: HomeState) => state.guardFilterStatus;
export const getGuardFilterText = (state: HomeState) => state.guardFilterText;
export const getGuardDetail = (state: HomeState) => state.guardDetail;
export const getGuardDetailLoading = (state: HomeState) => state.guardDetailLoading;
export const getSelectedGuard = (state: HomeState) =>
  state.guards?.find((guard) => guard.id === state.selectedGuardId);
export const getSelectedAlarm = (state: HomeState) =>
  state.activeAlarms?.find((alarm) => alarm.id === state.selectedAlarmId);
export const getSelectedReportId = (state: HomeState) => state.selectedReportId;
export const getCurrentTab = (state: HomeState) => state.currentTab;

export const homeReducer = createReducer(
  initialState,
  on(actions.pollingInitialLoadAction, (state) => ({
    ...state,
    activeAlarmsTableLoading: true,
    guardsTableLoading: true,
  })),
  on(
    actions.pollingInitialLoadSuccessAction,
    (state, { activeAlarmsResponse, guardsResponse }) => ({
      ...state,
      activeAlarmsTableLoading: false,
      activeAlarmsTableError: false,
      guardsTableLoading: false,
      guardsTableError: false,
      activeAlarms: activeAlarmsResponse.activeAlarms,
      activeAlarmsLastModifiedAt: activeAlarmsResponse.lastModifiedAt,
      guards: guardsResponse.activeGuards,
      guardsLastModifiedAt: guardsResponse.lastModifiedAt,
    }),
  ),
  on(actions.pollingInitialLoadFailAction, (state) => ({
    ...state,
    activeAlarmsTableLoading: false,
    activeAlarmsTableError: true,
    guardsTableLoading: false,
    guardsTableError: true,
  })),
  on(actions.activeAlarmsPollerFailAction, (state) => ({
    ...state,
    activeAlarmsTableLoading: false,
    activeAlarmsTableError: true,
  })),
  on(actions.activeAlarmsPollerRestartAction, (state) => ({
    ...state,
    activeAlarmsTableLoading: true,
  })),
  on(actions.activeAlarmsSuccessAction, (state, { payload }) => {
    const activeAlarmIdsSet = new Set(payload.activeAlarmIds);
    const alarmsWithUpdateSet = new Set(payload.activeAlarms.map((alarm) => alarm.id));
    // Remove alarms that are no longer active (ie. not in activeAlarmIds), then alarms with updates (via activeAlarms)
    const alarmsOfflineAndWithUpdateFiltered = state.activeAlarms
      .filter((alarm) => activeAlarmIdsSet.has(alarm.id))
      .filter((alarm) => !alarmsWithUpdateSet.has(alarm.id));
    const newActiveAlarms = [...alarmsOfflineAndWithUpdateFiltered, ...payload.activeAlarms];
    const newLastModifiedAt =
      payload?.lastModifiedAt < state?.activeAlarmsLastModifiedAt
        ? state.activeAlarmsLastModifiedAt
        : payload.lastModifiedAt;
    return {
      ...state,
      activeAlarmsLastModifiedAt: newLastModifiedAt,
      activeAlarms: newActiveAlarms,
    };
  }),
  on(actions.guardsPollerFailAction, (state) => ({
    ...state,
    guardsTableLoading: false,
    guardsTableError: true,
  })),
  on(actions.guardsPollerRestartAction, (state) => ({ ...state, guardsTableLoading: true })),
  on(actions.guardsSuccessAction, (state, { payload }) => {
    const inactiveGuardUsernamesSet = new Set(payload.inactiveGuardUsernames);
    const guardsWithUpdates = new Set(payload.activeGuards.map((guard) => guard.id));
    // remove out inactive guards, then guards with update
    const guardsOfflineAndWithUpdateFiltered = state.guards
      .filter((guard) => !inactiveGuardUsernamesSet.has(guard.username))
      .filter((guard) => !guardsWithUpdates.has(guard.id));
    const newGuards = [...guardsOfflineAndWithUpdateFiltered, ...payload.activeGuards];
    const newLastModifiedAt =
      payload?.lastModifiedAt < state?.guardsLastModifiedAt
        ? state.guardsLastModifiedAt
        : payload.lastModifiedAt;
    return {
      ...state,
      guardsLastModifiedAt: newLastModifiedAt,
      guards: newGuards,
    };
  }),
  on(actions.positionsSuccessAction, (state, { positions }): HomeState => {
    const guardWithPositions = state.guards.map((guard) => {
      const position = positions.find((pos) => pos.username === guard.username);
      return { ...guard, position };
    });
    return ({ ...state, guards: guardWithPositions })
  }),
  on(actions.clearErrors, (state): HomeState => ({ ...state, error: undefined })),
  on(
    actions.dispatchAlarmAction,
    (state): HomeState => ({ ...state, dispatchAlarmLoading: true, error: undefined }),
  ),
  on(
    actions.dispatchAlarmSuccessAction,
    (state): HomeState => ({ ...state, dispatchAlarmLoading: false }),
  ),
  on(
    actions.dispatchAlarmFailAction,
    (state, { error }): HomeState => ({ ...state, dispatchAlarmLoading: false, error }),
  ),
  on(
    actions.dispatchPatrolAction,
    (state): HomeState => ({ ...state, dispatchAlarmLoading: true, error: undefined }),
  ),
  on(
    actions.dispatchPatrolSuccessAction,
    (state): HomeState => ({ ...state, dispatchAlarmLoading: false }),
  ),
  on(
    actions.dispatchPatrolFailAction,
    (state, { error }): HomeState => ({ ...state, dispatchAlarmLoading: false, error }),
  ),
  on(actions.completedAlarmsAction, (state) => ({
    ...state,
    completedAlarms: {
      content: [],
      page: {},
    },
    completedAlarmsLoading: true,
  })),
  on(actions.completedAlarmsSuccessAction, (state, { completedAlarms }) => ({
    ...state,
    completedAlarms,
    completedAlarmsLoading: false,
  })),
  on(actions.completedAlarmsFailAction, (state) => ({ ...state, completedAlarmsLoading: false })),
  on(actions.reportFilterTextAction, (state, { reportFilterText }) => ({
    ...state,
    reportFilterText,
    reportPageNumber: 0,
  })),
  on(actions.reportFilterTextClearAction, (state) => ({
    ...state,
    reportFilterText: '',
    reportPageNumber: 0,
  })),
  on(actions.reportFilterDateRangeAction, (state, { reportFilterDateRange }) => ({
    ...state,
    reportFilterDateRange,
    reportPageNumber: 0,
  })),
  on(
    actions.reportChangePageAction,
    (state, { pageEvent }): HomeState => ({
      ...state,
      reportPageNumber: pageEvent.pageIndex,
      reportPageSize: pageEvent.pageSize,
    }),
  ),
  on(
    actions.reportChangeSortAction,
    (state, { sort }): HomeState => ({
      ...state,
      reportSortProperty: sort.active,
      reportSortDirection: sort.direction,
      reportPageNumber: 0,
    }),
  ),
  on(actions.alarmSelectAction, (state, { selectedAlarmId }) => ({ ...state, selectedAlarmId })),
  on(actions.alarmSelectClearAction, (state) => ({ ...state, selectedAlarmId: undefined })),
  on(actions.activeAlarmFilterTextAction, (state, { activeAlarmFilterText }) => ({
    ...state,
    activeAlarmFilterText,
  })),
  on(actions.activeAlarmFilterStatusAction, (state, { activeAlarmFilterStatus }) => ({
    ...state,
    activeAlarmFilterStatus,
  })),
  on(actions.guardSelectAction, (state, { selectedGuardId }) => ({ ...state, selectedGuardId })),
  on(actions.guardSelectClearAction, (state) => ({ ...state, selectedGuardId: undefined })),
  on(actions.guardFilterTextAction, (state, { guardFilterText }) => ({
    ...state,
    guardFilterText,
  })),
  on(actions.guardFilterStatusAction, (state, { guardFilterStatus }) => ({
    ...state,
    guardFilterStatus,
  })),
  on(actions.reportSelectAction, (state, { selectedReportId }) => ({ ...state, selectedReportId })),
  on(actions.reportSelectClearAction, (state) => ({ ...state, selectedReportId: undefined })),
  on(actions.tabAction, (state, { currentTab }) => ({ ...state, currentTab })),
  on(actions.guardDetailAction, (state) => ({ ...state, guardDetailLoading: true })),
  on(actions.guardDetailSuccessAction, (state, { guardDetail }) => ({
    ...state,
    guardDetail,
    guardDetailLoading: false,
  })),
  on(actions.guardDetailFailAction, (state) => ({ ...state, guardDetailLoading: false })),
  on(actions.guardDetailClearAction, (state) => ({ ...state, guardDetail: undefined })),
  on(actions.loadSubscriberInfoSuccess, (state, { subscriberInfo }) => ({
    ...state,
    subscriberInfo,
  })),
  on(actions.loadSubscriberInfoFail, (state) => ({ ...state, subscriberInfo: [] })),
);
