import { createAction, props } from '@ngrx/store';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Page, RspndrActiveAlarmsUpdate, RspndrGuardsUpdate } from '../@core/@api/portal';
import {
  RspndrAlarm,
  RspndrAlarmState,
  RspndrGuard,
  RspndrGuardState,
  RspndrIncident, RspndrPosition,
  RspndrSimpleSubscriberDto,
} from '../@core/@models/rspndr';

export const HOME_ACTION_TYPES = {
  TAB: '[Home] Tab',
  CLEAR_NOTIFICATIONS: '[Home] Clear Notifications',
  INACTIVE_BROWSER_LISTENER: '[Home] Inactive Browser Listener',
  POLLING_INITIAL_LOAD: '[Home] Polling Initial Load',
  POLLING_INITIAL_LOAD_SUCCESS: '[Home] Polling Initial Load Success',
  POLLING_INITIAL_LOAD_FAIL: '[Home] Polling Initial Load Fail',
  ACTIVE_ALARMS_POLLER: '[Home] Active Alarms Poller',
  ACTIVE_ALARMS_POLLER_FAIL: '[Home] Active Alarms Poller Fail',
  ACTIVE_ALARMS_POLLER_RESTART: '[Home] Active Alarms Poller Restart',
  ACTIVE_ALARMS_GUARDS_POLLER_STOP: '[Home] Active Alarms and Guards Poller Stop',
  ACTIVE_ALARMS: '[Home] Active Alarms',
  ACTIVE_ALARMS_SUCCESS: '[Home] Active Alarms Success',
  GUARDS_POLLER: '[Home] Guards Poller',
  GUARDS_POLLER_FAIL: '[Home] Guards Poller Fail',
  GUARDS_POLLER_RESTART: '[Home] Guards Poller Restart',
  POSITIONS_POLLER: '[Home] Positions Poller',
  POSITIONS_POLLER_FAIL: '[Home] Positions Poller Fail',
  POSITIONS_POLLER_RESTART: '[Home] Positions Poller Restart',
  POSITIONS_SUCCESS: '[Home] Positions Success',
  GUARDS: '[Home] Guards',
  GUARDS_SUCCESS: '[Home] Guards Success',
  CLEAR_ERRORS: '[Home] Clear Errors',
  DISPATCH_ALARM: '[Home] Dispatch Alarm',
  DISPATCH_ALARM_SUCCESS: '[Home] Dispatch Alarm Success',
  DISPATCH_ALARM_FAIL: '[Home] Dispatch Alarm Fail',
  DISPATCH_PATROL: '[Home] Dispatch Patrol',
  DISPATCH_PATROL_SUCCESS: '[Home] Dispatch Patrol Success',
  DISPATCH_PATROL_FAIL: '[Home] Dispatch Patrol Fail',
  COMPLETED_ALARMS: '[Home] Completed Alarms',
  COMPLETED_ALARMS_SUCCESS: '[Home] Completed Alarms Success',
  COMPLETED_ALARMS_FAIL: '[Home] Completed Alarms Fail',
  GUARD_SELECT: '[Home] Guard Select',
  GUARD_SELECT_SUCCESS: '[Home] Guard Select Success',
  GUARD_SELECT_CLEAR: '[Home] Guard Select Clear',
  ALARM_SELECT: '[Home] Alarm Select',
  ALARM_SELECT_SUCCESS: '[Home] Alarm Select Success',
  ALARM_SELECT_CLEAR: '[Home] Alarm Select Clear',
  REPORT_SELECT: '[Home] Report Select',
  REPORT_SELECT_CLEAR: '[Home] Report Select Clear',
  ACTIVE_ALARM_SORT: '[Home] Active Alarm Sort',
  ACTIVE_ALARM_FILTER_TEXT: '[Home] Active Alarm Filter Text',
  ACTIVE_ALARM_FILTER_STATUS: '[Home] Active Alarm Filter Status',
  REPORT_SORT: '[Home] Report Sort',
  REPORT_FILTER_TEXT: '[Home] Report Filter Text',
  REPORT_FILTER_TEXT_CLEAR: '[Home] Report Filter Text Clear',
  REPORT_FILTER_DATE_RANGE: '[Home] Report Filter Date Range',
  REPORT_CHANGE_PAGE: '[Home] Report Change Page',
  REPORT_CHANGE_SORT: '[Home] Report Change Sort',
  GUARD_SORT: '[Home] Guard Sort',
  GUARD_FILTER_TEXT: '[Home] Guard Filter Text',
  GUARD_FILTER_STATUS: '[Home] Guard Filter Status',
  GUARD_DETAIL: '[Home] Guard Detail',
  GUARD_DETAIL_SUCCESS: '[Home] Guard Detail Success',
  GUARD_DETAIL_FAIL: '[Home] Gaurd Detail Fail',
  GUARD_DETAIL_CLEAR: '[Home] Guard Detail Clear',
  NOOP: '[Home] No Op',
};

export interface DateRangeModel {
  start: string;
  end: string;
}

export const inactiveBrowserListenerAction = createAction(
  HOME_ACTION_TYPES.INACTIVE_BROWSER_LISTENER,
);
export const pollingInitialLoadAction = createAction(HOME_ACTION_TYPES.POLLING_INITIAL_LOAD);
export const pollingInitialLoadSuccessAction = createAction(
  HOME_ACTION_TYPES.POLLING_INITIAL_LOAD_SUCCESS,
  props<{ activeAlarmsResponse: RspndrActiveAlarmsUpdate; guardsResponse: RspndrGuardsUpdate }>(),
);
export const pollingInitialLoadFailAction = createAction(
  HOME_ACTION_TYPES.POLLING_INITIAL_LOAD_FAIL,
);
export const activeAlarmsPollerAction = createAction(HOME_ACTION_TYPES.ACTIVE_ALARMS_POLLER);
export const activeAlarmsPollerFailAction = createAction(
  HOME_ACTION_TYPES.ACTIVE_ALARMS_POLLER_FAIL,
);
export const activeAlarmsPollerRestartAction = createAction(
  HOME_ACTION_TYPES.ACTIVE_ALARMS_POLLER_RESTART,
);
export const activeAlarmsGuardsPollerStopAction = createAction(
  HOME_ACTION_TYPES.ACTIVE_ALARMS_GUARDS_POLLER_STOP,
);
export const activeAlarmsAction = createAction(HOME_ACTION_TYPES.ACTIVE_ALARMS);
export const activeAlarmsSuccessAction = createAction(
  HOME_ACTION_TYPES.ACTIVE_ALARMS_SUCCESS,
  props<{ payload: RspndrActiveAlarmsUpdate }>(),
);
export const guardsPollerAction = createAction(HOME_ACTION_TYPES.GUARDS_POLLER);
export const guardsPollerFailAction = createAction(HOME_ACTION_TYPES.GUARDS_POLLER_FAIL);
export const guardsPollerRestartAction = createAction(HOME_ACTION_TYPES.GUARDS_POLLER_RESTART);
export const positionsPollerAction = createAction(HOME_ACTION_TYPES.POSITIONS_POLLER);
export const positionsPollerFailAction = createAction(HOME_ACTION_TYPES.POSITIONS_POLLER_FAIL);
export const positionsPollerRestartAction = createAction(HOME_ACTION_TYPES.POSITIONS_POLLER_RESTART);
export const positionsSuccessAction = createAction(
  HOME_ACTION_TYPES.POSITIONS_SUCCESS,
  props<{ positions: RspndrPosition[] }>(),
);
export const guardsAction = createAction(HOME_ACTION_TYPES.GUARDS);
export const guardsSuccessAction = createAction(
  HOME_ACTION_TYPES.GUARDS_SUCCESS,
  props<{ payload: RspndrGuardsUpdate }>(),
);
export const clearErrors = createAction(HOME_ACTION_TYPES.CLEAR_ERRORS);
export const dispatchAlarmAction = createAction(
  HOME_ACTION_TYPES.DISPATCH_ALARM,
  props<{ incident: RspndrIncident }>(),
);
export const dispatchAlarmSuccessAction = createAction(HOME_ACTION_TYPES.DISPATCH_ALARM_SUCCESS);
export const dispatchAlarmFailAction = createAction(
  HOME_ACTION_TYPES.DISPATCH_ALARM_FAIL,
  props<{ error: Error }>(),
);
export const dispatchPatrolAction = createAction(
  HOME_ACTION_TYPES.DISPATCH_PATROL,
  props<{ incident: RspndrIncident }>(),
);
export const dispatchPatrolSuccessAction = createAction(HOME_ACTION_TYPES.DISPATCH_PATROL_SUCCESS);
export const dispatchPatrolFailAction = createAction(
  HOME_ACTION_TYPES.DISPATCH_PATROL_FAIL,
  props<{ error: Error }>(),
);
export const completedAlarmsAction = createAction(HOME_ACTION_TYPES.COMPLETED_ALARMS);
export const completedAlarmsSuccessAction = createAction(
  HOME_ACTION_TYPES.COMPLETED_ALARMS_SUCCESS,
  props<{ completedAlarms: Page<RspndrAlarm> }>(),
);
export const completedAlarmsFailAction = createAction(HOME_ACTION_TYPES.COMPLETED_ALARMS_FAIL);
export const guardSelectAction = createAction(
  HOME_ACTION_TYPES.GUARD_SELECT,
  props<{ selectedGuardId: string }>(),
);
export const guardSelectSuccessAction = createAction(HOME_ACTION_TYPES.GUARD_SELECT_SUCCESS);
export const guardSelectClearAction = createAction(HOME_ACTION_TYPES.GUARD_SELECT_CLEAR);
export const alarmSelectAction = createAction(
  HOME_ACTION_TYPES.ALARM_SELECT,
  props<{ selectedAlarmId: string }>(),
);
export const alarmSelectSuccessAction = createAction(HOME_ACTION_TYPES.ALARM_SELECT_SUCCESS);
export const alarmSelectClearAction = createAction(HOME_ACTION_TYPES.ALARM_SELECT_CLEAR);
export const reportSelectAction = createAction(
  HOME_ACTION_TYPES.REPORT_SELECT,
  props<{ selectedReportId: string }>(),
);
export const reportSelectClearAction = createAction(HOME_ACTION_TYPES.REPORT_SELECT_CLEAR);
export const tabAction = createAction(HOME_ACTION_TYPES.TAB, props<{ currentTab: string }>());
export const clearNotificationsAction = createAction(HOME_ACTION_TYPES.CLEAR_NOTIFICATIONS);
export const activeAlarmFilterTextAction = createAction(
  HOME_ACTION_TYPES.ACTIVE_ALARM_FILTER_TEXT,
  props<{ activeAlarmFilterText: string }>(),
);
export const activeAlarmFilterStatusAction = createAction(
  HOME_ACTION_TYPES.ACTIVE_ALARM_FILTER_STATUS,
  props<{ activeAlarmFilterStatus: RspndrAlarmState }>(),
);
export const reportFilterTextAction = createAction(
  HOME_ACTION_TYPES.REPORT_FILTER_TEXT,
  props<{ reportFilterText: string }>(),
);
export const reportFilterTextClearAction = createAction(HOME_ACTION_TYPES.REPORT_FILTER_TEXT_CLEAR);
export const reportFilterDateRangeAction = createAction(
  HOME_ACTION_TYPES.REPORT_FILTER_DATE_RANGE,
  props<{ reportFilterDateRange: DateRangeModel }>(),
);
export const reportChangePageAction = createAction(
  HOME_ACTION_TYPES.REPORT_CHANGE_PAGE,
  props<{ pageEvent: PageEvent }>(),
);
export const reportChangeSortAction = createAction(
  HOME_ACTION_TYPES.REPORT_CHANGE_SORT,
  props<{ sort: Sort }>(),
);
export const guardFilterTextAction = createAction(
  HOME_ACTION_TYPES.GUARD_FILTER_TEXT,
  props<{ guardFilterText: string }>(),
);
export const guardFilterStatusAction = createAction(
  HOME_ACTION_TYPES.GUARD_FILTER_STATUS,
  props<{ guardFilterStatus: RspndrGuardState }>(),
);
export const guardDetailAction = createAction(
  HOME_ACTION_TYPES.GUARD_DETAIL,
  props<{ guardId: string }>(),
);
export const guardDetailSuccessAction = createAction(
  HOME_ACTION_TYPES.GUARD_DETAIL_SUCCESS,
  props<{ guardDetail: RspndrGuard }>(),
);
export const guardDetailFailAction = createAction(HOME_ACTION_TYPES.GUARD_DETAIL_FAIL);
export const guardDetailClearAction = createAction(HOME_ACTION_TYPES.GUARD_DETAIL_CLEAR);
export const noOpAction = createAction(HOME_ACTION_TYPES.NOOP);

export const loadSubscriberInfo = createAction(
  '[Home] Load Subscriber Info',
  props<{ tenantId: string; customerNumber: string }>(),
);

export const loadSubscriberInfoSuccess = createAction(
  '[Home] Load Subscriber Info Success',
  props<{ subscriberInfo: RspndrSimpleSubscriberDto[] }>(),
);

export const loadSubscriberInfoFail = createAction(
  '[Home] Load Subscriber Info Fail',
  props<{ error: Error }>(),
);
