import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RspndrConfig } from '../@models/common';
import { RspndrPosition } from '../@models/rspndr';

@Injectable()
export class RspndrPortalPositionApi {
  private readonly basePath = '/internal/portal/position';

  constructor(private config: RspndrConfig, private http: HttpClient) {}

  getPositionByUserId(userId: string): Observable<RspndrPosition> {
    return this.http.get<RspndrPosition>(`${this.config.baseUrl}${this.basePath}/user/${userId}`);
  }

  getAllOnlineGuardPositions(): Observable<RspndrPosition[]> {
    return this.http.get<RspndrPosition[]>(`${this.config.baseUrl}${this.basePath}/online/all`);
  }

  getAllOnlineGuardPositionsByTenantId(tenantId: string): Observable<RspndrPosition[]> {
    return this.http.get<RspndrPosition[]>(`${this.config.baseUrl}${this.basePath}/online/tenant/${tenantId}`);
  }

  getAllOnlineGuardPositionsByTenantIdNLOps(tenantId: string): Observable<RspndrPosition[]> {
    return this.http.get<RspndrPosition[]>(`${this.config.baseUrl}${this.basePath}/online/tenant/${tenantId}/nl-ops`);
  }

  getAllOnlineGuardPositionsForAssignedAlarms(tenantId: string): Observable<RspndrPosition[]> {
    return this.http.get<RspndrPosition[]>(`${this.config.baseUrl}${this.basePath}/online/tenant/${tenantId}/assigned`);
  }
}
